/**
 * 渲染统计数据
 */
export enum RenderStatisticsInfo{
    DrawCall,
    InstanceDrawCall,
    Triangle,
    UniformUpload,//TODO
    GPUMemory,
    TextureMemeory,
    RenderTextureMemory,
    BufferMemory,
}